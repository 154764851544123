import React from 'react';
import Slider from 'react-slick';
import '../styles/AdvertisementCarousel.css'; // Custom CSS for the carousel
import advertisementVideo1 from '../images/video1.mp4'; // Import the MP4 video file
// import advertisementVideo2 from '../images/video2.mp4';
import advertisementVideo3 from '../images/video3.mp4';

const AdvertisementCarousel = () => {
  const courses = [
    {
      title: 'Learn Selenium with Udemy!',
      description: 'Master Selenium for web automation with hands-on projects.',
      video: advertisementVideo1,
      // link: 'https://www.udemy.com/course/zero-to-hero-in-selenium-webdriver-java-basics-to-advance/?referralCode=CE1EB90EDF90EFA3D6D5',
      link: 'https://www.udemy.com/course/cucumber-java-tutorial/?referralCode=A29218DAF04EF769F1AA',
    },
    // {
    //   title: 'Cucumber BDD course',
    //   description: 'Master Cucumber BDD and streamline Agile testing with clear, automated scenarios. By Shelendra.',
    //   video: advertisementVideo2,
    //   link: 'https://www.udemy.com/course/cucumber-java-tutorial/?referralCode=A29218DAF04EF769F1AA',
    // },
    {
      title: 'Cucumber BDD course',
      description: 'Master Cucumber BDD and streamline Agile testing with clear, automated scenarios. By Shelendra.',
      video: advertisementVideo3,
      // link: 'https://www.udemy.com/course/cucumber-java-tutorial/?referralCode=A29218DAF04EF769F1AA',
       link: 'https://www.udemy.com/course/zero-to-hero-in-selenium-webdriver-java-basics-to-advance/?referralCode=CE1EB90EDF90EFA3D6D5',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {courses.map((course, index) => (
          <div key={index} className="course-slide" onClick={() => window.open(course.link, '_blank')}>
            <video className="course-video"  autoPlay loop muted>
              <source src={course.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <div className="course-info"> */}
              {/* <h3>{course.title}</h3> */}
              {/* <p>{course.description}</p> */}
            {/* </div> */}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AdvertisementCarousel;

import React from 'react';
import './styles/App.css';
import Navbar from './components/Navbar';
import AdvertisementCarousel from './components/AdvertisementCarousel'; // Import AdvertisementCarousel
import ApiFeature from './components/ApiFeature';
import Footer from './components/Footer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="content">
        <div className="left-content">
          <ApiFeature />
        </div>
        <div className="right-advertisement">
          <AdvertisementCarousel />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;

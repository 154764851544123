import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ApiFeature.css';
// import AdvertisementCarousel from './AdvertisementCarousel';
import apiData from '../ApiData';

const ApiFeature = () => {
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [responseCode, setResponseCode] = useState(null);
  const [isBodyCopied, setIsBodyCopied] = useState(false);
  const [isRequestBodyCopied, setIsRequestBodyCopied] = useState(false); // New state for request body copying


  //  const baseURL = process.env.REACT_APP_API_BASE_URL || 'api.techrequestresponse.com';
  const baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
  // Use the imported apiData for requests
  const requests = apiData.map((request, index) => ({
    id: index + 1,
    label: request.name,
    url: request.requestUrl.URL,  // Adjust this according to how URLs are stored in apiData
    method: request.requestType,
    body: request.request?.body || null,  // Capture the body data for POST, PUT, PATCH
    headers: request.request?.headers || null,  // Capture headers if they exist
  }));

  useEffect(() => {
    // Fetch the first request by default when the component loads
    if (requests.length > 0) {
      handleRequestClick(requests[0]);
    }
  }, []);

  const handleCopyResponse = () => {
    console.log(responseData)
    navigator.clipboard.writeText(JSON.stringify(responseData, null, 2));
    setIsBodyCopied(true);
    setTimeout(() => setIsBodyCopied(false), 2000);
  };
  const handleCopyRequestBody = () => {
    if (selectedRequest?.body) {
      navigator.clipboard.writeText(JSON.stringify(selectedRequest.body, null, 2));
      setIsRequestBodyCopied(true);
      setTimeout(() => setIsRequestBodyCopied(false), 2000);
    }
  };

  const handleRequestClick = (request) => {
    console.log("request ", request)
    setSelectedRequest(request);
    setLoading(true);
    setResponseCode(null);


    // Build headers dynamically, ensuring headers exist
    const headers = {
      ...(request.headers || {}), // Spread existing headers from apiData.js or initialize an empty object
    };

    // // If the request requires a Bearer token, handle it dynamically
    // if (request.headers?.authorization && request.headers.authorization.includes('BRtoken')) {
    //   const token = "VISHU"; // You can retrieve or store the token dynamically here
    //   headers['Authorization'] = `Bearer ${token}`; // Add the token to the headers
    // }
    // Handle authentication based on the request header structure
    if (request.headers) {
      // Check for Bearer token
      if (typeof request.headers.Authorization === 'string' && request.headers.Authorization.startsWith("Bearer")) {
        headers['Authorization'] = request.headers.Authorization; // Directly use the Bearer token
      }
      // Check for Basic Auth
      else if (request.headers.Authorization?.Username && request.headers.Authorization?.Password) {
        const { Username, Password } = request.headers.Authorization;
        headers['Authorization'] = `Basic ${btoa(`${Username}:${Password}`)}`; // Set Basic Auth header
      }
      // Check for API Key
      else if (request.headers.Authorization && request.headers.Authorization.method === "Select API Key") {
        const { Key, value } = request.headers.Authorization;
        headers[Key] = value; // Set the API key in headers
      }
    }

    console.log("Headers being sent: ", headers); // Log headers for debugging

    // const baseURL  = process.env.REACT_APP_API_BASE_URL;  // Access the environment variable
    // console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);

    // console.log("Making request to:", `${baseURL}${request.url}`);
    // console.log("Request method:", request.method);
    // console.log("Request body:", request.body);
    // console.log("Request headers:", headers);

    // Handle axios request  https://goodseee.onrender.com
    axios({
      method: request.method.toLowerCase(),
      url: `${baseURL}${request.url}`,  // Ensure the correct base URL
      data: request.body || null,  // Include body data for POST/PUT/PATCH requests
      headers,


      // Apply headers including authorization if applicable
    })
      .then(response => {
        console.log("Response data: ", response.data); // Log the response for debugging
        setResponseData(response.data);
        setResponseCode(response.status);
        setLoading(false);
      })
      .catch(error => {
        const errorMessage = error.response
          ? `Error: ${error.response.data.message || error.response.statusText}, Status Code: ${error.response.status}`
          : 'Network Error';
        console.error("Error response: ", error); // Log error for debugging
        setResponseData({ error: errorMessage });
        setResponseCode(error.response ? error.response.status : 'Network Error');
        setLoading(false);
      });
  };

  return (
    <>
      <h2 className="api-heading">API Requests</h2>
      <div className="api-container">
        <div className="api-requests">
          <h3>Requests</h3>
          <ul>
            {requests.map(request => (
              <li
                key={request.id}
                onClick={() => handleRequestClick(request)}
                className={selectedRequest?.id === request.id ? 'active' : ''}
              >
                <span className={`request-method ${request.method.toLowerCase()}`}>
                  {request.method}
                </span>
                <span className="request-label">{request.label}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className={`api-response ${loading ? 'loading' : ''}`}>
          <h3>Request</h3>

          {selectedRequest && (
            <>
              <p>
                <strong>Request Type:</strong> {selectedRequest.method}
              </p>

              <p>
                <strong>Request URL:</strong>{' '}
                {/* <a href={selectedRequest.url} target="_blank" rel="noopener noreferrer">
                  {selectedRequest.url}
                </a> */}
                <a href={`${baseURL}${selectedRequest.url}`} target="_blank" rel="noopener noreferrer">
                  {`${baseURL}${selectedRequest.url}`}
                </a>
              </p>

              {/* New section for API request data should be placed here */}
              {/* Display Request Headers */}
              {selectedRequest.headers && (
                <div>
                  <h4>Request Headers:</h4>
                  <pre>{JSON.stringify(selectedRequest.headers, null, 2)}</pre> {/* Display only the headers */}
                </div>
              )}

              {(selectedRequest.method === 'POST' || selectedRequest.method === 'PUT' || selectedRequest.method === 'PATCH') && (
                <div>



                  <h4>Request Body Data:</h4>
                  <div className="response-container">
                    <div className="copy-icon-wrapper">
                      <span className="copy-icon" onClick={handleCopyRequestBody}>📋</span>
                      {isRequestBodyCopied && <span className="copied-label">Copied!</span>}
                    </div>
                    <pre>{JSON.stringify(selectedRequest.body, null, 2)}</pre>
                  </div>

                </div>
              )}
            </>
          )}

          <pre>
            <h4 className='response' style={{ fontSize: 'larger' }}>Response</h4>
            <p><strong>Response Code:</strong> {responseCode}</p>
          </pre>
          {loading ? (
            <p>Loading...</p>


          ) : (

            <div className="response-container">
              <div className="copy-icon-wrapper">
                {selectedRequest?.method !== 'DELETE' && (
                  <>
                    <span className="copy-icon" onClick={handleCopyResponse}>📋</span>
                    {isBodyCopied && <span className="copied-label">Copied!</span>}
                  </>
                )}
              </div>

              {/* Render responseData without using JSON.stringify to avoid double quotes */}
              <pre>{typeof responseData === 'object' ? JSON.stringify(responseData, null, 2) : responseData}</pre>

              {/* Check response data in console */}
              {console.log(responseData)}
            </div>
          )}
        </div>
      </div>


    </>
  );
};

export default ApiFeature;
